<template>
  <div class="activity-channel flex justify-center items-center flex-col h-full w-full">
    <font-awesome-icon class="text-surface-400 mb-2" :icon="['fal', 'messages']" size="2xl" />
    <small class="text-surface-400">No channel selected</small>
  </div>
</template>

<script setup></script>

<style lang="less" scoped></style>
